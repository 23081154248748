// Aqui se centralizan los strings de las rutas al API
export class API {
    public static webApiBase = process.env.VUE_APP_ROOT_API;
    public static webApiBaseDashBoard = API.webApiBase + 'Dashboard/';
    public static webApiBaseDashBoardEmpresarial = API.webApiBase + 'DashboardNegocio/';
    public static webApiBaseDashBoardPacientes = API.webApiBase + 'DashboardPaciente/';
    public static login = API.webApiBase + 'Login';

    public static usuarios = API.webApiBase + 'Usuarios';

    public static roles = API.webApiBase + 'Roles';

    public static paciente = API.webApiBase + 'paciente';

    /******************************Dashboards**************************************************************** */
    /*Home*/
    public static DashHome = API.webApiBaseDashBoard + 'Home';
    public static DashCitasSemanaActual = API.webApiBaseDashBoard + 'CitasSemanaActual';
    public static DashCitasCitasRealizadasVsPendientes = API.webApiBaseDashBoard + 'CitasRealizadasVsPendientes';
    public static DashCitasPendientes = API.webApiBaseDashBoard + 'CitasPendientes';
    public static DashCitasRealizadas = API.webApiBaseDashBoard + 'CitasRealizadas';
    public static DashCitasRealizadasPorPaciente = API.webApiBaseDashBoard + 'CitasRealizadasPorPaciente';
    public static DashMediaCitasAno = API.webApiBaseDashBoard + 'MediaCitasAno';
    public static DashPacienteConMasCitas = API.webApiBaseDashBoard + 'PacienteConMasCitas';
    /*Empresarial*/
    public static AgendaAgruCitas = API.webApiBaseDashBoardEmpresarial + 'AgendaAgruCitas';
    public static EstadisticasCita = API.webApiBaseDashBoardEmpresarial + 'EstadisticasCitas';
    public static AltasDepacientes = API.webApiBaseDashBoardEmpresarial + 'AltasDepacientes';
    public static TotalHombresMujeres = API.webApiBaseDashBoardEmpresarial + 'TotalHombresMujeres';
    public static AgrupacionPorDatos = API.webApiBaseDashBoardEmpresarial + 'AgrupacionPorDatos';
    public static EvolucionEntrevistas = API.webApiBaseDashBoardEmpresarial + 'EvolucionEntrevistas';
    public static EvolucionPacientes = API.webApiBaseDashBoardEmpresarial + 'EvolucionPacientes';
    /*Pacientes*/
    public static DashPaciEvolucionPacientes = API.webApiBaseDashBoardPacientes + 'EvolucionPaciente';
    public static DashPaciEvolucionPacienteAntesDespues = API.webApiBaseDashBoardPacientes + 'EvolucionPacienteAntesDespues';
    /******************************Dashboards**************************************************************** */

    public static Configuracion = API.webApiBase + 'Configuracion';
    public static NutricionistaImagen = API.webApiBase + 'NutricionistaImagen';
    public static appointments = API.webApiBase + 'appointments';
    public static resources = API.webApiBase + 'resources';
    public static patologia = API.webApiBase + 'patologia';
    public static historial_patologias_paciente = API.webApiBase + 'historial_patologias_paciente';
    public static entrevista = API.webApiBase + 'entrevista';
    public static modelo_horarios = API.webApiBase + 'modelo_horarios';
    public static ActividadFisica = API.webApiBase + 'ActividadFisica';
    public static PracticaDeporte = API.webApiBase + 'PracticaDeporte';
    public static cabezera_preguntas = API.webApiBase + 'cabezera_preguntas';
    public static hist_preguntas_paciente = API.webApiBase + 'hist_preguntas_paciente';
    public static etiqueta = API.webApiBase + 'etiqueta';
    public static VersionBack = API.webApiBase + 'VersionBack';
    public static Emails = API.webApiBase + 'Emails';
    public static anotaciones = API.webApiBase + 'anotaciones';
    public static lineas_pregunta = API.webApiBase + 'lineas_preguntas';
    public static tipos_campos_personalizados = API.webApiBase + 'tipos_campos_personalizados';
    public static campos_personalizados = API.webApiBase + 'campos_personalizados';
    public static cab_valor_campos_personalizado = API.webApiBase + 'cab_valor_campos_personalizados';
    public static Licencia = API.webApiBase + 'Licencia';
    public static objetivos_medicion = API.webApiBase + 'objetivos_mediciones';
    public static diagnosticos_y_vacunas = API.webApiBase + 'diagnosticos_y_vacunas';
    public static paciente_conf_email = API.webApiBase + 'paciente_conf_email';
    public static nutricionista_conf_email = API.webApiBase + 'nutricionista_conf_emails';
    public static Marketing = API.webApiBase + 'Marketing';
    public static diagnosticos_y_vacuna = API.webApiBase + 'diagnosticos_y_vacunas';
    public static medicamento = API.webApiBase + 'medicamentos';
    public static cirugia = API.webApiBase + 'cirugias';
    public static hist_clinico = API.webApiBase + 'hist_clinico';
    public static dieta = API.webApiBase + 'dieta';
    public static alimento = API.webApiBase + 'nueva_alimentos';
    public static nueva_plato = API.webApiBase + 'nueva_platos';
    public static suplemento = API.webApiBase + 'suplementos';
    public static dificultad_plato = API.webApiBase + 'dificultad_plato';
    public static gustos_paciente = API.webApiBase + 'gustos_paciente';
    public static accesos_app = API.webApiBase + 'accesos_app';
    public static Nutricionista = API.webApiBase + 'Nutricionistas';
    public static SendingBlue = API.webApiBase + 'SendingBlue';
    public static avisos = API.webApiBase + 'avisos';
    public static registrostestweb = API.webApiBase + 'registrostestweb';
    public static cambioscontrasenasperdidas = API.webApiBase + 'cambioscontrasenasperdidas';
    public static modelo = API.webApiBase + 'modelos';
    public static AdminNutricionista = API.webApiBase + 'AdminNutricionista';
    public static admin_registro_llamada = API.webApiBase + 'admin_registro_llamadas';
    public static registros_probar_app = API.webApiBase + 'registros_probar_app';
    public static cabezeras_platos = API.webApiBase + 'cabezeras_platos';
    public static soporte_tikerin = API.webApiBase + 'soporte_tikerin';
    public static mediciones_caseras = API.webApiBase + 'mediciones_caseras';
    public static MymedicaldietIntegrations = API.webApiBase + 'MymedicaldietIntegrations';
    public static recomendacion = API.webApiBase + 'recomendacion';
    public static recomendacion_patologias = API.webApiBase + 'recomendacion_patologias';
    public static PagosStripe = API.webApiBase + 'PagosStripe';
    public static Personalizaciones = API.webApiBase + 'Personalizaciones';
    public static personalizaciones_c_371_entrevista = API.webApiBase + 'personalizaciones_c_371_entrevista';
    public static anotaciones_dieta = API.webApiBase + 'anotaciones_dieta';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';



}
